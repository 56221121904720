@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;900&display=swap');

:root {
  --neon-purple: #b026ff;
  --neon-blue: #4d4dff;
  --dark-bg: #0a0014;
}

.App {
  min-height: 100vh;
  background: var(--dark-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Orbitron', sans-serif;
  position: relative;
  overflow: hidden;
}

.cyber-grid {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(90deg, rgba(176, 38, 255, 0.1) 1px, transparent 1px) 0 0 / 40px 40px,
    linear-gradient(rgba(176, 38, 255, 0.1) 1px, transparent 1px) 0 0 / 40px 40px;
  transform: perspective(500px) rotateX(60deg);
  animation: gridMove 20s linear infinite;
}

.content-container {
  position: relative;
  z-index: 1;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
}

.glitch-container {
  position: relative;
}

.name-title {
  font-size: 5rem;
  font-weight: 900;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  position: relative;
  text-shadow: 
    0 0 10px var(--neon-purple),
    0 0 20px var(--neon-purple),
    0 0 40px var(--neon-purple);
  animation: textFlicker 3s infinite alternate;
}

.name-title::before,
.name-title::after {
  content: 'DADDY SATAMQX';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.name-title::before {
  color: #0ff;
  animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  transform: translate(5px);
}

.name-title::after {
  color: #f0f;
  animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
  transform: translate(-5px);
}

.discord-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(88, 101, 242, 0.1);
  border: 2px solid var(--neon-blue);
  border-radius: 12px;
  padding: 0.8rem 1.5rem;
  margin: 2rem auto 0;
  transition: all 0.3s ease;
  transform-style: preserve-3d;
  animation: float 4s ease-in-out infinite;
  max-width: fit-content;
  position: relative;
  cursor: pointer;
}

.discord-card:hover {
  transform: translateY(-5px) scale(1.02);
  background: rgba(88, 101, 242, 0.2);
  box-shadow: 
    0 0 10px rgba(88, 101, 242, 0.3),
    0 0 20px rgba(88, 101, 242, 0.2),
    0 0 30px rgba(88, 101, 242, 0.1);
}

.discord-icon {
  font-size: 2rem;
  color: #5865F2;
  animation: pulseIcon 2s infinite;
}

.discord-tag {
  font-size: 2rem;
  color: #fff;
  text-shadow: 
    0 0 10px rgba(88, 101, 242, 0.8),
    0 0 20px rgba(88, 101, 242, 0.4);
  font-weight: bold;
  letter-spacing: 1px;
  padding: 0 0.5rem;
}

.gaming-message {
  background: rgba(176, 38, 255, 0.1);
  border: 2px solid var(--neon-purple);
  border-radius: 10px;
  padding: 1.5rem 2rem;
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  transform-style: preserve-3d;
  transition: all 0.3s ease;
  max-width: 500px;
  margin: 0 auto;
}

.gaming-message::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(176, 38, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

.social-icon {
  font-size: 3rem;
  color: var(--neon-purple);
  margin: 2rem 0;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
}

.social-icon i {
  transition: all 0.3s ease;
}

.social-icon::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100px;
  background: radial-gradient(circle, var(--neon-purple) 0%, transparent 70%);
  opacity: 0;
  transition: all 0.3s ease;
  transform: scale(0);
}

.social-icon:hover::before {
  opacity: 0.2;
  transform: scale(1);
}

.social-icon:hover i {
  transform: scale(1.2);
  color: #fff;
  text-shadow: 0 0 20px var(--neon-purple);
}

@keyframes gridMove {
  0% { transform: perspective(500px) rotateX(60deg) translateY(0); }
  100% { transform: perspective(500px) rotateX(60deg) translateY(40px); }
}

@keyframes textFlicker {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
}

@keyframes glitch {
  0% { transform: translate(0); }
  20% { transform: translate(-5px, 5px); }
  40% { transform: translate(-5px, -5px); }
  60% { transform: translate(5px, 5px); }
  80% { transform: translate(5px, -5px); }
  100% { transform: translate(0); }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes shine {
  0% { transform: rotate(45deg) translateX(-100%); }
  100% { transform: rotate(45deg) translateX(100%); }
}

@keyframes pulseGlow {
  0%, 100% {
    box-shadow: 0 0 5px var(--neon-blue),
                0 0 10px var(--neon-blue),
                0 0 15px var(--neon-blue);
  }
  50% {
    box-shadow: 0 0 10px var(--neon-blue),
                0 0 20px var(--neon-blue),
                0 0 30px var(--neon-blue);
  }
}

.gaming-message:hover .discord-tag {
  transform: scale(1.05);
  background: rgba(77, 77, 255, 0.2);
}

@keyframes pulseIcon {
  0%, 100% {
    transform: scale(1);
    filter: drop-shadow(0 0 5px rgba(88, 101, 242, 0.8));
  }
  50% {
    transform: scale(1.1);
    filter: drop-shadow(0 0 10px rgba(88, 101, 242, 0.8));
  }
}

.discord-card {
  position: relative;
  cursor: pointer;
}

.copy-tooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  background: rgba(88, 101, 242, 0.9);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.discord-card:hover .copy-tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.copy-notification {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  background: rgba(46, 213, 115, 0.9);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.copy-notification.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.copy-notification i {
  font-size: 0.9rem;
}

.discord-card:active {
  transform: scale(0.95);
}

.discord-card::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  background: linear-gradient(45deg, #5865F2, #7289DA);
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease;
}

.discord-card:hover::after {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .name-title {
    font-size: 3rem;
  }
  .discord-tag {
    font-size: 1.6rem;
  }
  .gaming-message {
    font-size: 1.2rem;
    padding: 1.2rem 1.5rem;
    max-width: 90%;
  }
  .discord-icon {
    font-size: 1.6rem;
  }
  .discord-card {
    padding: 0.6rem 1.2rem;
  }
  .copy-tooltip,
  .copy-notification {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
}
